import { HTMLProps } from "react";

const Input = (props: HTMLProps<HTMLInputElement>) => {
  return (
    <input
      {...props}
      className="py-3 px-4 border-none outline-none rounded-md w-full text-base md:text-lg"
    />
  );
};

export default Input;
