const Spinner = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      opacity={0.4}
      d="M21.712 17.55l-1.347-.77a.77.77 0 01-.336-.929 8.904 8.904 0 11-7.184-12.714l-.108-.01c-.41-.034-.737-.354-.737-.766V.812a.774.774 0 01.742-.774C12.495.022 12.251 0 12 0 5.371 0 0 5.371 0 12s5.371 12 12 12c4.742 0 8.827-2.756 10.776-6.746-.2.38-.688.51-1.064.295z"
    />
    <path d="M12.737 3.127c-.41-.034-.737-.354-.737-.766V.809c0-.44.371-.811.812-.782C19.06.444 24 5.645 24 12a11.904 11.904 0 01-1.21 5.237c-.193.396-.695.533-1.077.313l-1.347-.77c-.358-.205-.476-.646-.3-1.02a8.832 8.832 0 00.837-3.76 8.904 8.904 0 00-8.166-8.873z" />
  </svg>
);

export default Spinner;
