import cx from "classnames";
export default function H1({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <h1
      className={cx(
        "text-3xl font-bold text-white md:text-4xl xl:text-5xl",
        className || ""
      )}
    >
      {children}
    </h1>
  );
}
