const ErrorSymbol = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      opacity={0.4}
      d="M8 0a8 8 0 100 16A8 8 0 008 0zm0 12.129a1.032 1.032 0 110-2.065 1.032 1.032 0 010 2.065zm1.234-7.69L8.82 8.566a.516.516 0 01-.514.465h-.613a.516.516 0 01-.513-.465l-.413-4.129a.516.516 0 01.512-.567h1.44a.516.516 0 01.514.567z"
    />
    <path d="M8.72 3.871H7.28a.516.516 0 00-.513.567l.413 4.13a.516.516 0 00.513.464h.613a.516.516 0 00.514-.465l.413-4.129a.516.516 0 00-.513-.567zM8 10.065a1.033 1.033 0 100 2.065 1.033 1.033 0 000-2.066z" />
  </svg>
);

export default ErrorSymbol;
