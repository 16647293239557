import { FormEvent, useState } from "react";
import ErrorSymbol from "@SVG/ErrorSymbol";

const CopyURL = () => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyURL = (e: FormEvent) => {
    e.preventDefault();
    const url = location.href;
    navigator.clipboard.writeText(url);
    setIsCopied(true);
  };

  return (
    <div className="hidden md:block md:bg-white md:rounded-md md:my-4">
      <div className="md:p-5">
        <div className="flex flex-row justify-start mb-3">
          <ErrorSymbol
            className="fill-current text-primary-100 mr-2"
            width={24}
            height={24}
          />
          <p className="text-primary-100">
            You are on desktop, we suggest you use a mobile device to get the
            most accurate results
          </p>
        </div>

        <button
          type="button"
          className="text-secondary-500 focus:outline-none no-underline border-none"
          onClick={handleCopyURL}
        >
          {isCopied ? <span>URL Copied</span> : <span>Copy URL</span>}
        </button>
      </div>
    </div>
  );
};

export default CopyURL;
