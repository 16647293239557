import ErrorSymbol from "@SVG/ErrorSymbol";
import cx from "classnames";

const ErrorBar = ({
  error,
  theme = "dark",
  style,
  className,
}: {
  error: string;
  className?: string;
  theme?: string;
  style?: object;
}) => {
  return (
    <div
      className={cx(
        "bg-white",
        "rounded-md",
        "flex",
        "flex-row",
        "justify-start",
        "p-3",
        "text-lg",
        className || ""
      )}
      style={style}
    >
      <div>
        <ErrorSymbol
          className="fill-current text-secondary-500"
          width={20}
          height={20}
          viewBox="0 0 16 16"
        />
      </div>
      <p className="text-base my-auto mx-2 text-primary-100">{error}</p>
    </div>
  );
};

export default ErrorBar;
