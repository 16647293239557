import Spinner from "@SVG/Spinner";
import cx from "classnames";

const SpinLoader = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <Spinner {...props} className={cx("animate-spin", props.className || "")} />
);

export default SpinLoader;
