import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";
import cx from "classnames";
import { ColorOptions } from "interfaces/colors.types";

interface Props
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children: React.ReactNode;
  width: "full" | "auto";
  color?: ColorOptions;
  className?: string;
}

const Button = (props: Props) => {
  let colorString: string;
  switch (props.color) {
    case "secondary":
      colorString = "bg-secondary-500 text-white";
      break;
    case "gray":
      colorString = "bg-gray-500 text-primary-100";
      break;
    case "light-gray":
      colorString = "bg-gray-900 text-primary-100";
      break;
    case "white":
      colorString = "bg-white text-primary-100";
      break;
    default:
      colorString = "bg-primary-100 text-white";
      break;
  }

  return (
    <button
      {...props}
      className={cx(
        "py-3 px-4 font-bold rounded-md focus:outline-none cursor-pointer flex justify-center md:text-xl",
        colorString,
        props.className || "",
        props.width === "full" ? "w-full" : "w-auto"
      )}
    >
      {props.children}
    </button>
  );
};

export default Button;
