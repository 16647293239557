import Layout from "components/Layout/Layout";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import firebaseClient from "firebase/client";
import { emailIsValid, passwordIsValid } from "utils/forms";
import ErrorBar from "components/ErrorBar/ErrorBar";
// import CSPartnership from "components/CSPartnership/CSPartnership";
import Link from "next/link";
import { useRouter } from "next/router";
import SpinLoader from "components/SpinLoader/SpinLoader";
import SocialSignOn from "components/SocialSignOn/SocialSignOn";
import CopyURL from "components/CopyURL/CopyURL";
import Container from "components/Container/Container";
import Input from "components/Form/Input";
import Button from "components/Form/Button";
import H1 from "components/TextElements/H1";
import { GetServerSidePropsContext } from "next";
import nookies from "nookies";
import admin from "firebase/admin";
import axios from "axios";
import routes from "constants/routes";

const emptyErrorObj: { [key: string]: string } = {
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
  consent: "",
  submit: "",
};

const Signup = ({ auth }: { auth: boolean }) => {
  // State that holds Signup form data
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    consent: true,
  });
  // State that holds Signup form errors
  const [formErrors, setFormErrors] = useState(emptyErrorObj);
  const [isLoading, setIsLoading] = useState(false);
  // const auth = useAuth();
  const router = useRouter();

  // Handle any form changes for inputs (not toggle)
  const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (formErrors[name]) {
      setFormErrors({
        ...formErrors,
        [name]: "",
      });
    }
  };

  const handleNameValidation: () => boolean = () => {
    if (!formData.firstName || !formData.lastName) {
      setFormErrors({
        ...formErrors,
        lastName: "Please provide a valid first and last name",
      });
      return false;
    }
    return true;
  };
  const handleEmailValidation: () => boolean = () => {
    if (!emailIsValid(formData.email)) {
      setFormErrors({
        ...formErrors,
        email: "Please provide a valid email address",
      });
      return false;
    }
    return true;
  };
  const handleConfirmPasswordValidation: () => boolean = () => {
    if (
      !formData.confirmPassword ||
      formData.password !== formData.confirmPassword
    ) {
      setFormErrors({
        ...formErrors,
        confirmPassword: "Your passwords do not match",
      });
      return false;
    }
    return true;
  };

  const handlePasswordValidation: () => boolean = () => {
    if (!passwordIsValid(formData.password)) {
      setFormErrors({
        ...formErrors,
        password:
          "Try including a password with a combination of one number, one lower case letter, one upper case letter, one special character and with a minimum length of 8 characters",
      });
      return false;
    }
    return true;
  };

  // Handle Signup with Email + Password
  const handleSignup = async (e: FormEvent) => {
    e.preventDefault();

    // Set form errors to empty
    setFormErrors(emptyErrorObj);
    setIsLoading(true);

    // Check that form data includes first and last name

    try {
      if (!handleNameValidation()) {
        return;
      }

      if (!handleEmailValidation()) {
        return;
      }
      if (!handleConfirmPasswordValidation()) {
        return false;
      }

      const userCredential = await firebaseClient
        .auth()
        .createUserWithEmailAndPassword(formData.email, formData.password);
      const user = userCredential.user;
      user?.sendEmailVerification();
      // Update user profile information
      user?.updateProfile({
        displayName: `${formData.firstName} ${formData.lastName}`,
      });
      const token = await user?.getIdToken();
      await axios.post(routes.API.LOGIN, { token });
      router.push(routes.VERIFY_EMAIL);
    } catch (e) {
      setIsLoading(false);
      if (e.code === "auth/weak-password") {
        setFormErrors({
          ...formErrors,
          password: "The password is too weak",
        });
      } else if (e.code === "auth/invalid-email") {
        setFormErrors({
          ...formErrors,
          email: "Please provide a valid email address",
        });
      } else if (e.code === "auth/email-already-in-use") {
        setFormErrors({
          ...formErrors,
          submit: "A user already exists with this email, please Login",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout
      auth={auth}
      seo={{
        title: "Sign Up | NuCalm®",
        index: true,
        follow: false,
        path: "signup",
        description:
          "Sign Up for NuCalm® Stress & Fatigue Assessment. Assess your stress and fatigue levels to learn how you can improve your stress and sleep",
      }}
    >
      <main>
        <Container width="sm" className="py-8">
          <H1 className="mb-4 md:mb-6">Sign Up</H1>
          <form>
            {formErrors.submit && (
              <ErrorBar className="mb-4" error={formErrors.submit} />
            )}
            {formErrors.lastName && (
              <ErrorBar error={formErrors.lastName} className="mb-4" />
            )}
            <div className="flex flex-row justify-between mb-4">
              <label htmlFor="firstName" className="mr-2">
                <Input
                  id="firstName"
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  value={formData.firstName}
                  onChange={handleFormChange}
                />
              </label>
              <label htmlFor="lastName" className="ml-2">
                <Input
                  id="lastName"
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  value={formData.lastName}
                  onChange={handleFormChange}
                  onBlur={handleNameValidation}
                />
              </label>
            </div>
            {formErrors.email && (
              <ErrorBar error={formErrors.email} className="mb-4" />
            )}
            <div className="mb-4">
              <label htmlFor="email">
                <Input
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleFormChange}
                  onBlur={handleEmailValidation}
                />
              </label>
            </div>

            {formErrors.password && (
              <ErrorBar error={formErrors.password} className="mb-4" />
            )}
            <div className="mb-4">
              <label htmlFor="password">
                <Input
                  id="password"
                  type="password"
                  value={formData.password}
                  name="password"
                  placeholder="Password"
                  onChange={handleFormChange}
                  onBlur={handlePasswordValidation}
                />
              </label>
            </div>

            {formErrors.confirmPassword && (
              <ErrorBar error={formErrors.confirmPassword} className="mb-4" />
            )}
            <div className="mb-2">
              <label htmlFor="password">
                <Input
                  id="confirmPassword"
                  type="password"
                  value={formData.confirmPassword}
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  onChange={handleFormChange}
                  onBlur={handleConfirmPasswordValidation}
                />
              </label>
            </div>

            <div className="text-white text-sm md:text-base pt-2 pb-4 md:pb-2">
              <p className="text-white">
                By taking this test you agree to be sent email updates about new
                developments, exclusive promotions and your NuCalm® Stress
                Report.
              </p>
            </div>
            <CopyURL />
            <Button
              width="full"
              color="white"
              onClick={handleSignup}
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? <SpinLoader /> : <span>Sign Up</span>}
            </Button>
          </form>
          <section className="py-3 text-white text-center">
            <p className="text-base md:text-lg">
              Already have an account?{" "}
              <Link href={routes.LOGIN} passHref>
                <a className="font-bold no-underline text-white">Log In</a>
              </Link>
            </p>
          </section>
          <SocialSignOn
            text="or sign up with"
            onError={(msg: string) =>
              setFormErrors({ ...formErrors, submit: msg })
            }
          />
          {/* <CSPartnership /> */}
        </Container>
      </main>
    </Layout>
  );
};

export const getServerSideProps = async (ctx: GetServerSidePropsContext) => {
  try {
    const cookies = nookies.get(ctx);
    const token = await admin.auth().verifySessionCookie(cookies.session);
    // If user is authenticated we route them to start
    if (token) {
      return {
        redirect: {
          destination: routes.START,
        },
      };
    }
    // If no token or error return with empty props obj
    return {
      props: {
        auth: false,
      },
    };
  } catch (err) {
    return {
      props: {
        auth: false,
      },
    };
  }
};

export default Signup;
