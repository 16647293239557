import { useRouter } from "next/router";
import client from "firebase/client";
import routes from "constants/routes";

const SocialSignOn = ({
  text,
  onError,
}: {
  text: string;
  onError: (msg: string) => void;
}) => {
  const router = useRouter();
  const googleSignIn = () => {
    // Using a popup.
    const provider = new client.auth.GoogleAuthProvider();
    client
      .auth()
      .signInWithPopup(provider)
      .then(() => {
        router.push(routes.START);
      })
      .catch((e) => {
        if (e.code === "auth/account-exists-with-different-credential") {
          onError(
            "Your account exists, but you signed up with a different method"
          );
        }
        throw e;
      });
  };

  const facebookSignIn = () => {
    const provider = new client.auth.FacebookAuthProvider();
    provider.addScope("email");
    client
      .auth()
      .signInWithPopup(provider)
      .then(() => {
        router.push(routes.START);
      })
      .catch((e) => {
        if (e.code === "auth/account-exists-with-different-credential") {
          onError(
            "Your account exists, but you signed up with a different method"
          );
        }
        throw e;
      });
  };
  const appleSignIn = () => {
    const provider = new client.auth.OAuthProvider("apple.com");
    provider.addScope("email");
    provider.addScope("name");
    client
      .auth()
      .signInWithPopup(provider)
      .then(() => {
        router.push(routes.START);
      })
      .catch((e) => {
        if (e.code === "auth/account-exists-with-different-credential") {
          onError(
            "Your account exists, but you signed up with a different method"
          );
        }
        throw e;
      });
  };

  return (
    <div className="flex flex-col justify-start my-5">
      <div>
        <p className="text-base text-white text-center mb-4 md:text-xl">
          {text}
        </p>
      </div>
      <div className="flex flex-row justify-center">
        <button
          type="button"
          onClick={googleSignIn}
          className="bg-white rounded-md py-3 px-3 mx-3 flex cursor-pointer shadow-lg"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={30}
            height={30}
            viewBox="0 0 30 30"
            fill="none"
            className="my-auto"
          >
            <g clipPath="url(#prefix__clip0)">
              <path
                d="M29.4 15.333a14.633 14.633 0 01-4.467 11.034h-4.85V22.6a6.85 6.85 0 002.984-4.533H15v-5.8h14.133c.175 1.013.264 2.038.267 3.066z"
                fill="#3E82F1"
              />
              <path
                d="M25 26.367A14.366 14.366 0 0115 30a15 15 0 01-13.333-8.333v-3.884h5A8.95 8.95 0 0015 23.967a9.05 9.05 0 005-1.434l5 3.834z"
                fill="#32A753"
              />
              <path
                d="M6.666 12.15a8.933 8.933 0 000 5.7l-5 3.817a15 15 0 010-13.467h5v3.95z"
                fill="#F9BB00"
              />
              <path
                d="M25 3.9l-4.3 4.317a8.05 8.05 0 00-5.733-2.25 8.95 8.95 0 00-8.333 6.183L1.667 8.333A15 15 0 0115 0a14.417 14.417 0 0110 3.9z"
                fill="#E74235"
              />
            </g>
            <defs>
              <clipPath id="prefix__clip0">
                <path fill="#fff" d="M0 0h29.4v30H0z" />
              </clipPath>
            </defs>
          </svg>
        </button>
        <button
          type="button"
          onClick={facebookSignIn}
          className="bg-white rounded-md py-3 px-3 mx-3 flex cursor-pointer shadow-lg"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            className="my-auto"
          >
            <path
              d="M30 15C30 6.71572 23.2843 0 15 0C6.71572 0 0 6.71572 0 15C0 22.4868 5.48525 28.6925 12.6562 29.8178V19.3359H8.84766V15H12.6562V11.6953C12.6562 7.93594 14.8957 5.85938 18.322 5.85938C19.9626 5.85938 21.6797 6.15234 21.6797 6.15234V9.84375H19.7883C17.925 9.84375 17.3438 11.0001 17.3438 12.1875V15H21.5039L20.8389 19.3359H17.3438V29.8178C24.5147 28.6925 30 22.4868 30 15Z"
              fill="#1877F2"
            />
            <path
              d="M20.8389 19.3359L21.5039 15H17.3438V12.1875C17.3438 11.0013 17.925 9.84375 19.7883 9.84375H21.6797V6.15234C21.6797 6.15234 19.9632 5.85938 18.322 5.85938C14.8957 5.85938 12.6562 7.93594 12.6562 11.6953V15H8.84766V19.3359H12.6562V29.8178C14.2093 30.0607 15.7907 30.0607 17.3438 29.8178V19.3359H20.8389Z"
              fill="white"
            />
          </svg>
        </button>
        <button
          type="button"
          onClick={appleSignIn}
          className="bg-white rounded-md py-3 px-3 mx-3 flex cursor-pointer shadow-lg"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={26}
            height={30}
            viewBox="0 0 26 30"
            fill="none"
            className="my-auto"
          >
            <g clipPath="url(#prefix__clip0)">
              <path
                d="M21.286 15.85c-.014-2.457 1.11-4.312 3.382-5.678-1.272-1.801-3.192-2.793-5.729-2.987-2.401-.187-5.026 1.386-5.986 1.386-1.015 0-3.341-1.319-5.168-1.319C4.011 7.312 0 10.232 0 16.172c0 1.754.325 3.567.974 5.437.866 2.458 3.99 8.485 7.25 8.384 1.705-.04 2.91-1.198 5.128-1.198 2.15 0 3.267 1.198 5.168 1.198 3.287-.047 6.114-5.524 6.94-7.988-4.41-2.056-4.174-6.027-4.174-6.155zM17.458 4.855C19.304 2.685 19.135.71 19.08 0c-1.63.094-3.517 1.098-4.593 2.337-1.183 1.326-1.88 2.967-1.731 4.815 1.765.134 3.375-.764 4.7-2.297z"
                fill="#000"
              />
            </g>
            <defs>
              <clipPath id="prefix__clip0">
                <path fill="#fff" d="M0 0h25.5v30H0z" />
              </clipPath>
            </defs>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default SocialSignOn;
